import { Modal, Image, Button, Alert } from "react-bootstrap";
import warning from "../../public/images/warning.png";
/* import bitpoint from '../public/images/bitpoint.png' */
import { useContext } from "react";
import { TpagaContext } from "../../context/TpagaProvider";

const ModalUserNotFound = (props) => {
  const { usernotfound, setusernotfound, setstep, setprogress } =
    useContext(TpagaContext);
  /* console.log('id_tpaga CONTEXT IN MODAL USER NOT FOUND', id_tpaga) */

  return (
    <>
      <Modal
        show={usernotfound.open}
        onHide={() => {
          setusernotfound({
            ...usernotfound,
            open: false,
            medium: "",
          });
        }}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Image className="img-warning" src={warning} />
          {usernotfound.medium === "tpaga" ? (
            <>
              <h4>
                Tenemos problemas para encontrar tu cuenta, intentalo más tarde
              </h4>
              <Button
                className="continue"
                variant="outline-secondary"
                size="lg"
                onClick={() => {
                  setusernotfound({
                    ...usernotfound,
                    open: false,
                    medium: "",
                  });
                }}
              >
                Entiendo
              </Button>
            </>
          ) : (
            <>
              <div className="welcome">
                <h4>Hemos detectado que no tienes una cuenta en BITPOINT</h4>
              </div>
              <Button
                className="continue"
                variant="success"
                size="lg"
                onClick={() => {
                  setstep(0);
                  setusernotfound({
                    ...usernotfound,
                    open: false,
                    medium: "",
                  });
                  setprogress(10);
                }}
              >
                Crear cuenta
              </Button>
              <br></br>
              <br></br>
              <Button
                className="continue"
                variant="outline-secondary"
                size="lg"
                onClick={() => {
                  setusernotfound({
                    ...usernotfound,
                    open: false,
                    medium: "",
                  });
                }}
              >
                Cancelar
              </Button>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalUserNotFound;

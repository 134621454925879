import React, { useState, useRef, useEffect, useContext } from "react";
import { Form, Modal, Button } from "react-bootstrap";

import { resendCode, confirmRegistration } from "../../utils/cognito";
import useCountDown from "../../hooks/useCountDown";
import { TpagaContext } from "../../context/TpagaProvider";

const ModalOptComponent = ({
  visible,
  digitalNumber,
  destination,
  username,
}) => {
  const { setregistersuccess } = useContext(TpagaContext);

  const [otp, setOtp] = useState(new Array(digitalNumber).fill(""));

  const [visibleRe, setVisibleRe] = useState(false);

  const [countdownValue, resetCountdown] = useCountDown({
    initialNumber: 20,
    endNumber: 0,
    interval: 1000,
    onCountdownFinish: () => {
      setVisibleRe(true);
      console.log("Countdown finished!");
    },
  });

  const [otpError, setOtpError] = useState(null);
  const otpBoxReference = useRef([]);

  useEffect(() => {
    const enteredOTP = otp.join("");
    if (enteredOTP.length === digitalNumber) {
      console.log("Verifying OTP:", enteredOTP);

      setOtpError(null);
    }
  }, [otp, digitalNumber]);

  const handleChange = (value, index) => {
    let newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);

    if (value && index < digitalNumber - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  };

  const handleBackspaceAndEnter = (e, index) => {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }
    if (e.key === "Enter" && e.target.value && index < digitalNumber - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  };

  const handlerConfirmation = async () => {
    try {
      await confirmRegistration(username, otp.join(""));
    } catch (error) {
      if (error?.code === "CodeMismatchException") {
        setOtpError("Código inválido");
        return;
      }
      if (error?.code === "ExpiredCodeException") {
        setOtpError("Codigo expirado / vuelva a enviarlo");
        return;
      }

      if (error?.code === "NotAuthorizedException") {
        setOtpError("Tu usuario ya ha sido verificado");
        return;
      }

      setOtpError("Ups Sucedio algo / intenta de nuevo");
    }
  };

  return (
    <Modal show={visible} centered>
      <Modal.Header closeButton>
        <Modal.Title>Verificación de cuenta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className={`text-lg text-dark`}>
          Hemos enviado un codigo de {digitalNumber} digitos
        </p>
        <p className={`text-lg text-dark`}>Codigo enviado a {destination}</p>
        <div className="d-flex justify-content-center mb-4">
          {otp.map((item, index) => (
            <Form.Control
              key={index}
              value={item}
              maxLength={1}
              onChange={(e) => handleChange(e.target.value, index)}
              onKeyDown={(e) => handleBackspaceAndEnter(e, index)}
              className="mx-1 text-center"
              ref={(el) => (otpBoxReference.current[index] = el)}
            />
          ))}
        </div>

        <p className={`text-lg text-center  text-dark mt-4`}>
          {countdownValue} segundos
        </p>

        <p className={`text-lg text-dark mt-4 ${otpError ? "error-show" : ""}`}>
          {otpError}
        </p>

        <div className="">
          {visibleRe && (
            <Button
              className="continue mb-3"
              variant="outline-secondary"
              size="lg"
              onClick={() => {
                resendCode(username);
                resetCountdown();
                setVisibleRe(false);
              }}
            >
              Reenviar codigo
            </Button>
          )}
          <Button
            className="continue"
            variant="success"
            size="lg"
            onClick={() => {
              handlerConfirmation();
              setregistersuccess(true);
            }}
          >
            Confirmar
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalOptComponent;

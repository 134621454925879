import { useContext } from 'react'
import { ProgressBar } from 'react-bootstrap'
import { TpagaContext } from '../../context/TpagaProvider'
const ProgressBarComponent = () => {
    const { progress } = useContext(TpagaContext)
    return (
        <>
            <ProgressBar now={progress} />
        </>
    )

}
export default ProgressBarComponent
import Alert from 'react-bootstrap/Alert';

const AlertComponent = () => {
    return (
        <Alert variant="warning">
            <Alert.Heading style={{ fontSize: "16px" }}></Alert.Heading>
            <p>
                <b>Revisa atentamente</b> que los datos sean correctos antes de finalizar tu depósito.
            </p>
            {/*   <hr />
            <p className="mb-0">
                Whenever you need to, be sure to use margin utilities to keep things
                nice and tidy.
            </p> */}
        </Alert>
    );
}

export default AlertComponent;
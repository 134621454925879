import { useContext } from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap'
import { TpagaContext } from '../../context/TpagaProvider';
const Modalconfirmation = () => {
    const { showconfirmation, setShowconfirmation } = useContext(TpagaContext)
    return (
        <>
            <Modal show={showconfirmation} fullscreen='xxl-down' onHide={() => {
                setShowconfirmation(false)
            }
            }>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmación de depósito</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <ProgressBar now={progress} /> */}
                    {/* <Image className="img-bitpoint" src={bitpoint} fluid /> */}
                    {/* <Row className="welcome"><span>Revisa que los datos sean correctos antes de cargar el exchange.</span></Row> */}
                    <hr></hr>
                    <Row>
                        <Col><span>Usuario:</span></Col>
                        <Col><b>Juan David Sánchez</b></Col>

                    </Row>
                    <hr></hr>
                    <Row>
                        <Col><span>Identificación:</span></Col>
                        {/* <Col><b>{data.identification}</b></Col> */}
                        <Col><b>test</b></Col>
                    </Row>
                    <hr></hr>
                    <Row>
                        <Col><span>Monto a depositar:</span></Col>
                        {/* <Col><b>{data.amount}</b></Col> */}
                        <Col><b>test</b></Col>
                    </Row>
                    <hr></hr>
                    <Row>
                        <Col><span>Hora:</span></Col>
                        <Col><b>{Date()}</b></Col>
                    </Row>
                    <hr></hr>
                    <Button className="continue" variant="success" size="lg" type="submit" onClick={() => {
                        setShowconfirmation(false);
                        /* setprogress(100) */
                    }} >
                        Confirmar
                    </Button>
                    <br></br>
                    <br></br>
                    <Button className="continue" variant="outline-secondary" size="lg" onClick={() => {
                        setShowconfirmation(false);

                    }}>Cancelar</Button>
                </Modal.Body>
            </Modal>
        </>
    );
}
export default Modalconfirmation
import { CognitoUserPool, CognitoUser } from "amazon-cognito-identity-js";
import { getUserPoolData } from "./config";

export const signUp = async (email, password, atributeList) => {
  const { ClientId, UserPoolId } = await getUserPoolData();
  const poolData = {
    UserPoolId,
    ClientId,
  };
  const userPool = new CognitoUserPool(poolData);

  return new Promise((resolve, reject) => {
    userPool.signUp(email, password, atributeList, [], (err, result) => {
      if (!err) {
        return resolve(result);
      }
      return reject(err);
    });
  });
};

export const resendCode = async (username) => {
  const { ClientId, UserPoolId } = await getUserPoolData();
  const poolData = {
    UserPoolId,
    ClientId,
  };
  const userPool = new CognitoUserPool(poolData);
  const userData = {
    Username: username,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);
  return new Promise((resolve, reject) => {
    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
        reject(err);
      } else {
        resolve(result);
      }
    });
  });
};

export const confirmRegistration = async (username, code) => {
  const { ClientId, UserPoolId } = await getUserPoolData();
  const poolData = {
    UserPoolId,
    ClientId,
  };
  const userPool = new CognitoUserPool(poolData);
  const userData = {
    Username: username,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);
  return new Promise((resolve, reject) => {
    cognitoUser.confirmRegistration(code, true, (err, result) => {
      if (err) {
        reject(err);
      } else {
        resolve(result);
      }
    });
  });
};

import { BitpointApi } from "./api/ApiBitpoint"
export const getUser = (id) => {
    let response
    response = BitpointApi.post("/tpaga/cashin/getuserfromtpaga", {
        id_tpaga: id
    })
        .then((res) => {
            //console.log('RESULT IN GET USER TPAGA', res)
            return res.data
        })
        .catch((err) => {
            console.log('ERROR RESULT IN GET USER TPAGA', err)
            return {
                statusCode: 400,
                data: err
            }
        })
    return response
}

export const registerUser = (data) => {
    let response
    response = BitpointApi.post("/users/registerAP", data)
        .then((res) => {
            console.log('RESULT IN REGISTER USER TPAGA', res)
            return res.data
        })
        .catch((err) => {
            console.log('ERROR RESULT IN GET USER TPAGA', err)
            return {
                statusCode: 400,
                data: err
            }
        })
    return response
}
export const geTtrm = (amount) => {

    let response = BitpointApi.post("/trm/fee", { amount: amount, iso: "COP" },
    ).then((res) => {
        console.log('RESULT IN GET TRM TPAGA', res.data)
        return res.data
    })
        .catch((err) => {
            console.log('ERROR RESULT IN GET TRM TPAGA', err)
            return {
                statusCode: 400,
                data: err
            }
        })
    return response
}
export const createDeposit = (data) => {
    let response = BitpointApi.post("/tpaga/cashin/createdepositstagin", data)
        .then((res) => {
            console.log('RESULT CREATE DEPOSIT TPAGA', res.data)
            return res.data
        })
        .catch((err) => {
            console.log('ERROR RESULT CREATE DEPOSIT TPAGA', err)
            return {
                statusCode: 400,
                data: err
            }
        })
    return response
}
export const getOpenDeposits = (AccountId) => {
    let response = BitpointApi.post("/tpaga/cashin/getopendeposits", { AccountId })
        .then((res) => {
            console.log('RESULT GET OPEN DEPOSITS TPAGA', res.data)
            return res.data
        })
        .catch((err) => {
            console.log('ERROR RESULT GET OPEN DEPOSITS TPAGA', err)
            return {
                statusCode: 400,
                data: err
            }
        })
    return response
}
export const closeDeposit = (data) => {
    let response = BitpointApi.post("/tpaga/cashin/rejectdeposit", data)
        .then((res) => {
            console.log('RESULT CLOSE DEPOSIT TPAGA', res.data)
            return res.data
        })
        .catch((err) => {
            console.log('ERROR CLOSE DEPOSIT TPAGA', err)
            return {
                statusCode: 400,
                data: err
            }
        })
    return response
}


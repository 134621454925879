import axios from "axios";

export const BitpointApi = axios.create({
  //development/v1/tpaga/cashin/getuserfromtpaga
  baseURL:
    "https://pn20z368i5.execute-api.us-east-1.amazonaws.com/development/v1",
  headers: {
    "Content-type": "application/json",
  },
});

export const BitpointApiProd = axios.create({
  baseURL: "https://pn20z368i5.execute-api.us-east-1.amazonaws.com/prod/v1/tpaga/cashin",
  headers: {
    "Content-type": "application/json",
  },
});
export const BitpointApiTpaga = axios.create({
  baseURL: "https://tpaga-api.bitpointlatam.de",
  headers: {
    "Content-type": "application/json",
  },
});



import React, { useCallback, useEffect, useState } from "react";

const useCountDown = ({
  initialNumber,
  endNumber,
  interval,
  onCountdownFinish,
}) => {
  const updateCurrentNumber = useCallback(
    (prev) => {
      if (prev !== endNumber) {
        return prev - 1;
      }
      return prev;
    },
    [endNumber]
  );

  const [currentNumber, setCurrentNumber] = useState(initialNumber);

  const resetCountdown = () => {
    setCurrentNumber(initialNumber);
  };

  useEffect(() => {
    const countdown = setInterval(() => {
      setCurrentNumber((prev) => updateCurrentNumber(prev));
    }, interval);

    return () => {
      clearInterval(countdown);
    };
  }, [updateCurrentNumber, interval]);

  useEffect(() => {
    if (
      currentNumber === endNumber &&
      typeof onCountdownFinish === "function"
    ) {
      onCountdownFinish();
    }
  }, [currentNumber, endNumber, onCountdownFinish]);

  return [currentNumber, resetCountdown];
};

export default useCountDown;

import React, { useContext } from "react";
import { Row, Button, Image } from "react-bootstrap";
import imgloading from '../public/images/loading.gif'
import { TpagaContext } from "../context/TpagaProvider";
const Home = () => {

    const { loading, getinfotpaga } = useContext(TpagaContext)

    return (
        <>
            <Row className="welcome">Vamos a cargar tu cuenta en bitpoint asociada a tu documento</Row>
            {loading ? <div>
                <Button className="continue" variant="success" size="lg" type="submit" disabled>Continuar</Button>
                <Image className="img-loading" src={imgloading} fluid />
            </div> : <Button className="continue" variant="success" size="lg" type="submit" onClick={() => { getinfotpaga() }}>Continuar</Button>}
        </>
    )
}
export default Home
import React, { useContext } from "react";
import { Row, Button } from 'react-bootstrap'
import { TpagaContext } from "../../context/TpagaProvider";
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar'

dayjs.extend(calendar)


const OpenDeposit = () => {
    const { openDeposit, ContinueDeposit, RejectDeposit } = useContext(TpagaContext);
    console.log('OPEN DEPOSIT', openDeposit)
    const { Amount, DepositInfo } = openDeposit
    let date = JSON.parse(DepositInfo).ExpiresDate
    date = dayjs(date).calendar(null, {
        sameDay: '[Hoy a las] hh:mm A', // Para la fecha de hoy
        nextDay: '[Mañana] hh:mm A', // Para la fecha de mañana
        nextWeek: 'dddd H:mm', // Para la próxima semana
        lastDay: '[Ayer] H:mm', // Para la fecha de ayer
        lastWeek: 'dddd H:mm', // Para la semana anterior
        sameElse: 'dddd, D [de] MMMM [del] YYYY [a las] H:mm' // Para cualquier otra fecha
    })


    return (
        <>
            <Row className="welcome">
                <p>
                    Hemos detectado que tienes un depósito abierto por valor de <b>{Amount} COP</b>. Esta transacción expira {date}
                </p>
            </Row>

            <Row className="welcome">
                <p>
                    ¿Desea continuar esta transacción?
                </p>
            </Row>
            <Button className="continue" variant="success" size="lg" onClick={ContinueDeposit} >
                Continuar transacción
            </Button>
            <br></br>
            <br></br>
            <Button className="continue" variant="outline-secondary" size="lg" onClick={RejectDeposit} >
                Iniciar nueva
            </Button>
            <span style={{
                color: "gray",
                fontSize: "12px"
            }}>
                Iniciar una nueva transacción cancela la anterior
            </span>
            {/*      <RowItem label="Fecha de creación" value={dayjs(date).locale('es').format('dddd DD MMMM YYYY hh:mm:ss A ')} />
            <RowItem label="Fecha de expiración" value={dayjs(expiresDate).locale('es').format('dddd DD MMMM YYYY hh:mm:ss A ')} />

            <div className="separator"></div>
            <a href={urlpayment} className="continue btn btn-success btn-lg" variant="success" size="lg">Confirmar depósito</a> */}

        </>
    );
};

export default OpenDeposit;

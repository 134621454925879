import React, { useContext } from "react";
import { Row } from "react-bootstrap";
import { TpagaContext } from "../../context/TpagaProvider";
import AlertComponent from "../Alert/Alert";
import dayjs from "dayjs";
import "dayjs/locale/es";

const RowItem = ({ label, value }) => (
  <Row style={{ margin: "5px", flexDirection: "column" }}>
    <div>
      <span style={{ color: "#6c757d", fontSize: "13.5px" }}>{label}:</span>
    </div>
    <div /* style={{ marginTop: "10px" }} */>
      <p>{value}</p>
    </div>
  </Row>
);

const ConfirmDeposit = () => {
  const { dataUser, dataTransaction } = useContext(TpagaContext);
  const { full_name, last_name, identification, email } = dataUser;
  const {
    amount,
    trm,
    fee,
    received,
    date,
    boost,
    ticketnumber,
    expiresDate,
    urlpayment,
  } = dataTransaction;

  return (
    <>
      <AlertComponent />
      <RowItem label="Nombre completo" value={`${full_name} ${last_name}`} />
      <RowItem label="Identificación" value={identification} />
      <RowItem label="Usuario bitpoint" value={email} />
      <RowItem label="Número de ticket" value={ticketnumber} />
      {/* <RowItem label="Referencia" value={code} /> */}
      <RowItem label="Monto a depositar" value={`${amount} COP`} />
      <RowItem label="TRM de cambio" value={`${trm} COP`} />
      <RowItem label="Comisión (3%)" value={`${fee} USD`} />
      {boost > 0 && <RowItem label="Bono" value={`${boost} USD`} />}
      <RowItem label="USD recibidos en BITPOINT" value={`${received} USD`} />

      <RowItem
        label="Fecha de creación"
        value={dayjs(date).locale("es").format("dddd DD MMMM YYYY hh:mm:ss A ")}
      />
      <RowItem
        label="Fecha de expiración"
        value={dayjs(expiresDate)
          .locale("es")
          .format("dddd DD MMMM YYYY hh:mm:ss A ")}
      />

      <div className="separator"></div>
      <a
        href={urlpayment}
        className="continue btn btn-success btn-lg"
        variant="success"
        size="lg"
      >
        Confirmar depósito
      </a>
      {/*        {loading ? (
                <div>
                    <Button className="continue" variant="success" size="lg" type="button" disabled>
                        Confirmar
                    </Button>
                    <Image className="img-loading" src={imgLoading} fluid />
                </div>
            ) : (
                <div>
                    <br></br>
                    <Button className="continue" variant="success" size="lg" onClick={CreateDeposit}>
                        Confirmar
                    </Button>
                    <br></br>
                    <br></br>
                    <Button className="continue" variant="outline-secondary" size="lg" onClick={() => {
                        setstep(1);
                        setprogress(33);
                    }}>Cancelar</Button>
                </div>
            )} */}
    </>
  );
};

export default ConfirmDeposit;

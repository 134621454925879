import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";

import MainView from "./views/MainView";
import SummaryPaymentView from "./views/SummaryPaymentView";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainView />} />
        <Route path="/summary/payment" element={<SummaryPaymentView />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;

import React, { useContext } from 'react'
import { Form, Button, Image } from 'react-bootstrap'
import imgLoading from '../../public/images/loading.gif'
import { TpagaContext } from '../../context/TpagaProvider'
const DepositForm = () => {
    const { CreateDeposit, loading, dataTransaction, onChangeTransaction } = useContext(TpagaContext)
    console.log('CONTEXTO', useContext(TpagaContext).dataTransaction)
    return (
        <>
            <Form onSubmit={CreateDeposit}>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Monto a depositar</Form.Label>
                    <Form.Control type="number" name="amount" min="100000" max="10000000" placeholder="100000" onChange={e => {
                        /*  console.log('EVENT IN DEPOSIT FORM', e.target) */
                        onChangeTransaction(e.target.name, e.target.value)
                    }} required />
                    <Form.Text className={parseInt(dataTransaction.amount) < dataTransaction.minamount ? 'text-red' : 'text-muted'}>
                        El monto mínimo a depositar es 100.000 COP
                    </Form.Text>
                </Form.Group>
                {parseInt(dataTransaction.amount) < dataTransaction.minamount ?

                    <Button className="continue" variant="success" size="lg" type="submit" disabled>
                        Continuar
                    </Button>
                    :
                    <React.Fragment>
                        {loading ? <div>
                            <Button className="continue" variant="success" size="lg" type="submit" disabled>Continuar</Button>
                            <Image className="img-loading" src={imgLoading} fluid />
                        </div> : <Button className="continue" variant="success" size="lg" type="submit">Continuar</Button>}
                    </React.Fragment>

                }

            </Form>
        </>
    )

}
export default DepositForm
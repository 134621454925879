import React, { useEffect, useState } from "react";
import { Image, Button } from 'react-bootstrap'
import bitpoint from "../public/images/bitpoint.png";
import imgloading from '../public/images/loading.gif'
import testSvg from '../public/images/test.svg'
import dayjs from "dayjs";
import "dayjs/locale/es";

import { BitpointApiTpaga } from "../api/ApiBitpoint";
const SummaryPaymentView = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const [data, setData] = useState(null);

  useEffect(() => {
    getByIdPayment();
    console.log("Entro por acá....")
  }, []);

  const getByIdPayment = async () => {
    try {
      const rspayment = await BitpointApiTpaga.get(
        `/transactions/deposit?id=${queryParams.get(
          "transactionID"
        )}&signature=${queryParams.get("signature")}`
      );
      console.log("RESPONSE PSE PAYMENT------", rspayment)
      if (rspayment.status === 200) {
        setData(rspayment.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const goBackToApp = () => {
    window.ReactNativeWebView.postMessage("goBack");
  };

  return (
    <div className="container align-items-center py-3">

      {data !== null ? (
        <>
          <div className="card card-custom-sumary">
            <Image className="img-bitpoint" src={bitpoint} fluid />
            <div className="card-body">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <span
                  style={{
                    fontSize: "1rem",
                    fontStyle: "italic",
                    color: "#A7A7A7",
                  }}
                >
                  {data?.status === "New" && "Tu pago está siendo procesado..."}
                </span>
                <span style={{ fontSize: "1.4rem", fontWeight: "semiBold" }}>
                  {data?.amount
                    .toLocaleString("es-CO", {
                      style: "currency",
                      currency: "COP",
                    })
                    .replace("$", "COP")}
                </span>
              </div>
              <div>
                <hr />
              </div>

              <div className="row">
                <div className="col">
                  <div className="row pb-2">
                    <div className="col-4">
                      <span>Referencia:</span>
                    </div>
                    <div className="col">
                      <span>{data?.referenceId}</span>
                    </div>
                  </div>
                  <div className="row pb-2">
                    <div className="col-4">
                      <span className="text-truncate">Correo:</span>
                    </div>
                    <div className="col">
                      <span className="text-truncate">
                        {data?.userId?.email}
                      </span>
                    </div>
                  </div>
                  <div className="row pb-2">
                    <div className="col-4">
                      <span>Pagaste:</span>
                    </div>
                    <div className="col">
                      <span>
                        {data?.amount
                          .toLocaleString("es-CO", {
                            style: "currency",
                            currency: "COP",
                          })
                          .replace("$", "COP")}</span>
                    </div>
                  </div>
                  <div className="row pb-2">
                    <div className="col-4">
                      <span>TRM:</span>
                    </div>
                    <div className="col">
                      <span>COP {data?.providerTrm} </span>
                    </div>
                  </div>
                  <div className="row pb-2">
                    <div className="col-4">
                      <span> Conversión:</span>
                    </div>
                    <div className="col">
                      <span>
                        {data?.amountToUsd.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).replace("$", "USD ")}
                      </span>
                    </div>
                  </div>
                  <div className="row pb-2">
                    <div className="col-4">
                      <span>Comisión:</span>
                    </div>
                    <div className="col">
                      <span>USD {data?.feeTotalUsd}</span>
                    </div>
                  </div>
                  <div className="row pb-2">
                    <div className="col-4">
                      <span>Recibirás:</span>
                    </div>
                    <div className="col">
                      <span>
                        {data?.amountToCreditUsd.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).replace("$", "USD ")}
                      </span>
                    </div>
                  </div>
                  <div className="row pb-2">
                    <div className="col-4">
                      <span>Fecha</span>
                    </div>
                    <div className="col">
                      <span>{dayjs(data?.createdAt).locale("es").format("ddd DD MMM YYYY hh:mm A ")}</span>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div>
            <br></br>
            <Button
              className="continue"
              variant="success"
              size="lg"
              onClick={goBackToApp}
            >
              Volver al comercio
            </Button>
          </div>
        </>
      ) : (
        <Image className="img-loading" src={imgloading} fluid />
      )}
    </div>
  );
};

export default SummaryPaymentView;

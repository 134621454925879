import React from "react";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";

import bitpoint from "../public/images/bitpoint.png";
import { TpagaProvider } from "../context/TpagaProvider";
import HomeView from "./HomeView";
import ProgressBarComponent from "../components/ProgressBar/ProgressBarComponent";
import Modalconfirmation from "../components/modals/ModalConfirmation";
import ModalUserNotFound from "../components/modals/ModalUserNotFound";
import ModalRegisterSuccess from "../components/modals/ModalRegisterSuccess";

const MainView = () => {
  return (
    <TpagaProvider>
      <Container className="p-3">
        <ProgressBarComponent />
        <Image className="img-bitpoint" src={bitpoint} fluid />

        <HomeView />
        <Modalconfirmation />
        <ModalUserNotFound />
        <ModalRegisterSuccess />
        <span
          style={{
            color: "gray",
          }}
        >
          <br></br>
          <br></br>
          {/*           Stagin enviroment 2.0.1 */}
        </span>
      </Container>
    </TpagaProvider>
  );
};

export default MainView;

import { createContext, useEffect, useState } from "react";

import { CognitoUserAttribute } from "amazon-cognito-identity-js";

import {
  closeDeposit,
  createDeposit,
  getOpenDeposits,
  geTtrm,
  getUser,
  registerUser,
  setVisibleOpt,
} from "../hooks";

import { signUp } from "../utils/cognito";

export const TpagaContext = createContext();

export const TpagaProvider = ({ children }) => {
  const [loading, setloading] = useState(false);
  const [disabled, setdisabled] = useState(false);
  const [validationCognito, setValidationCognito] = useState(false);
  const [visibleOpt, setVisibleOpt] = useState(false);
  const [messageCognito, setMessageCognito] = useState("");

  const [username, setUsername] = useState("");
  const [destination, setDestination] = useState("");

  const [steps, setstep] = useState(4);
  const [dataTransaction, setDataTransaction] = useState({
    amount: "",
    trm: "",
    fixedFee: 0.03, //Comisión 3% de TPAGA
    minamount: 100000, //Monto mínimo para depósito TPAGA
    fee: "",
    received: "",
    date: Date(),
  });
  const [dataUser, setDataUser] = useState({
    full_name: "",
    last_name: "",
    telephone: "",
    email: "",
    identification: "",
    country: "CO",
    password: "",
    amount: "",
    AccountId: "",
  });
  const [progress, setprogress] = useState(1);
  const [usernotfound, setusernotfound] = useState({
    open: false,
    medium: "",
  });

  const [emailinvalidated, setemailinvalidated] = useState(false);
  const [idinvalidated, setidinvalidated] = useState(false);
  const [registersuccess, setregistersuccess] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const id_tpaga = useState(
    queryParams.get("tpagaMiniappUserId")
      ? queryParams.get("tpagaMiniappUserId")
      : ""
  );
  //console.log('ID TPAGA', id_tpaga)
  const [pay_success, setpaysucess] = useState(
    queryParams.get("paySuccess") ? queryParams.get("paySuccess") : ""
  );
  const [openDeposit, setOpenDeposit] = useState({});

  useEffect(() => {
    if (pay_success === "success") {
      setstep(5);
      setprogress(100);
    } else {
      setprogress(10);
    }
  }, [pay_success]);

  const onChangeSignUp = (property, value) => {
    setDataUser({ ...dataUser, [property]: value });
  };
  const onChangeTransaction = (property, value) => {
    setDataTransaction({ ...dataTransaction, [property]: value });
  };
  const getinfotpaga = async () => {
    setloading(true);
    await getUser(id_tpaga[0])
      .then(async (res) => {
        switch (res.statuscode) {
          case 200:
            console.log("success 200", res);
            setDataUser({
              ...dataUser,

              first_name: res.full_name,
              last_name: res.last_name,
              telephone: res.telephone,
              email: res.email,
              identification: res.Identification,
              AccountId: res.AccountId,
            });
            await getOpenDeposits(res.AccountId).then((res) => {
              if (res.success) {
                if (res.deposits.length > 0) {
                  setOpenDeposit(res.deposits[0]);
                  setstep(6);
                } else {
                  setstep(1);
                  setprogress(33);
                }
              }
            });
            setloading(false);

            break;
          case 400:
            setloading(false);
            setusernotfound({
              ...usernotfound,
              open: true,
              medium: "tpaga",
            });
            break;
          case 422:
            console.log("not found 422", res);
            setloading(false);
            setusernotfound({
              ...usernotfound,
              open: true,
              medium: "tpaga",
            });
            break;
          case 423:
            console.log("success 423", res);
            setloading(false);
            setDataUser({
              full_name: res.data.first_name,
              last_name: res.data.last_name,
              telephone: res.data.phone_number,
              email: res.data.email,
              identification: res.data.identification_number,
              country_input: "CO",
              password_input: "",
            });
            setusernotfound({
              ...usernotfound,
              open: true,
              medium: "bitpoint",
            });
            break;

          default:
            setloading(false);
            setusernotfound({
              ...usernotfound,
              open: true,
              medium: "tpaga",
            });
            break;
        }
      })
      .catch(() => {
        setloading(false);
        setusernotfound({
          ...usernotfound,
          open: true,
          medium: "tpaga",
        });
      })
      .finally

      /*  setloading(false) */
      ();
  };
  const RegisterUser = async (e) => {
    e.preventDefault();
    setloading(true);
    setdisabled(true);
    setValidationCognito(false);
    setMessageCognito("");

    const payloadRegister = {
      Email: dataUser.email,
      Password: dataUser.password,
      Telephone: dataUser.telephone,
      /*    affiliate_tag: "", */
      utm_source: "TPAGA",
      utm_medium: "App",
      IsAPUser: "true",
      IsPerson: "true",
      IsBusiness: "false",
      Identification: dataUser.identification,
    };
    console.log("Register clicker and payload", payloadRegister);

    try {
      const attributeList = [
        new CognitoUserAttribute({
          Name: "email",
          Value: dataUser.email,
        }),
        new CognitoUserAttribute({
          Name: "custom:first_name",
          Value: dataUser.full_name,
        }),
        new CognitoUserAttribute({
          Name: "custom:last_name",
          Value: dataUser.last_name,
        }),
        new CognitoUserAttribute({
          Name: "custom:identificationNumber",
          Value: dataUser.identification,
        }),
        new CognitoUserAttribute({
          Name: "custom:identificationType",
          Value: "CC",
        }),
        new CognitoUserAttribute({
          Name: "phone_number",
          Value: dataUser.telephone,
        }),
      ];
      const sigcognito = await signUp(
        dataUser.email,
        dataUser.password,
        attributeList
      );
      setVisibleOpt(true);
      setloading(false);

      const { username } = sigcognito?.user;
      const { Destination } = sigcognito?.codeDeliveryDetails;
      setUsername(username);
      setDestination(Destination);
    } catch (error) {
      console.log(error);
      setloading(false);
      setVisibleOpt(false);
      setdisabled(false);
      if (error.code === "InvalidPasswordException") {
        setValidationCognito(true);
        setMessageCognito("Contraseña no cumple con lo requisitos");
        return;
      }

      if (error.code === "InvalidParameterException") {
        setValidationCognito(true);
        setMessageCognito("Numero celular no valido");
        return;
      }

      if (error.code === "UsernameExistsException") {
        setValidationCognito(true);
        setMessageCognito("Tu cuenta ya existe");
        return;
      }
    }
  };
  const CalculateFee = async (e) => {
    setloading(true);
    e.preventDefault();
    /* const payloadTrm = {
    
        } */
    await geTtrm(dataTransaction.amount)
      .then((res) => {
        console.log("RESPONSE TRM IN PROVIDER", res);

        switch (res.statusCode) {
          case 201:
            setDataTransaction({
              ...dataTransaction,
              trm: res.body.TRM2,
              received: parseFloat(
                res.body.toUSD - res.body.toUSD * dataTransaction.fixedFee
              ).toFixed(2),
              fee: parseFloat(
                res.body.toUSD * dataTransaction.fixedFee
              ).toFixed(2),
            });
            setprogress(66);
            setstep(2);
            break;

          default:
            break;
        }
      })
      .catch((err) => {});
    setloading(false);
    /*         var identificacion = identification_tpaga;
                var amount = data.amount;
                { amount < 100000 ? setminamount(false) : setminamount(true) }
                let body = {
                  identification: identificacion
                }
                let headers = {
                  'x-api-key': 'hIodL6wn14df8BmHHRKD3tAu2JhCWZA8eHM6XAL7',
                  'Access-Control-Allow-Origin': '*',
                  'Access-Control-Allow-Headers': '*',
                }
                const getuser = await axios.post(
                  `https://3wuaivsou0.execute-api.us-east-1.amazonaws.com/Development/api/user/auth/getuserprod`,
                  body,
                );
                console.log('response', getuser)
                if (getuser.data.statusCode == 201) {
                  const gettrm = await axios.post(
                    `https://3wuaivsou0.execute-api.us-east-1.amazonaws.com/Development/api/wallet/trm/fee`,
                    { amount: amount, iso: "COP" },
                  );
                  console.log('TRM', gettrm);
                  if (gettrm.data.TRM) {
                    settrm({
                      TRM: gettrm.data.TRM2,
                      COPtoUSD: gettrm.data.toUSD
                    })
                  }
                  setData({
                    full_name: getuser.data.body.full_name,
                    last_name: getuser.data.body.last_name,
                    email: getuser.data.body.email,
                    amount: data.amount,
                    identification: data.identification
                  })
                  setAccountId(getuser.data.body.AccountId)
                  setuserfound(true)
                  setstep(2)
                  setloading(false)
                  setprogress(66)
                } else {
                  setuserfound(false)
                  setloading(false)
                  setusernotfound(true)
                }
                console.log('response getuser', userfound) */
  };
  const CreateDeposit = async (e) => {
    setloading(true);
    e.preventDefault();

    //event.preventDefault();
    const payloadTransaction = {
      identification: dataUser.identification,
      amount: dataTransaction.amount,
      telephone: dataUser.telephone,
      accountId: dataUser.AccountId,
    };
    await createDeposit(payloadTransaction)
      .then((res) => {
        let depositInfo = JSON.parse(res.DepositInfo);
        const {
          Code,
          Boost,
          TRM,
          UsdReceivied,
          fee,
          ExpiresDate,
          TpagaPaymentUrl,
        } = depositInfo;

        console.log("DEPOSIT INFO parse", depositInfo);
        setDataTransaction({
          ...dataTransaction,
          trm: TRM,
          received: parseFloat(UsdReceivied.replace("USD", "")),
          fee: parseFloat(fee.replace("USD", "")),
          expiresDate: ExpiresDate,
          code: Code,
          boost: parseFloat(Boost.replace("USD", "")),
          ticketnumber: res.TicketNumber,
          urlpayment: TpagaPaymentUrl,
        });
        setprogress(66);
        setstep(2);
      })
      .catch((err) => {});
    /*        let body = {
                 accountId: AccountId,
                 identification: identification_tpaga,
                 amount: data.amount,
                 telephone: telephone_tpaga
               }
               console.log('BODY FOR DEPOSIT', body)
               const createdeposit = await axios.post(
                 `https://3wuaivsou0.execute-api.us-east-1.amazonaws.com/Development/api/wallet/tpaga/createdeposit`,
                 body,
               );
               console.log('TICKET DEPOSITO', createdeposit);
               if (createdeposit.data.success) {
                 setloading(false)
                 seturl(createdeposit.data.data.tpaga_payment_url)
                 setstep(3);
                 setprogress(90)
               } */
    setloading(false);
  };
  const ContinueDeposit = async () => {
    let depositInfo = JSON.parse(openDeposit.DepositInfo);
    const { TicketNumber } = openDeposit;
    const {
      Code,
      Boost,
      TRM,
      UsdReceivied,
      fee,
      ExpiresDate,
      TpagaPaymentUrl,
    } = depositInfo;

    console.log("DEPOSIT INFO parse", depositInfo);
    setDataTransaction({
      ...dataTransaction,
      trm: TRM,
      received: parseFloat(UsdReceivied.replace("USD", "")),
      fee: parseFloat(fee.replace("USD", "")),
      expiresDate: ExpiresDate,
      code: Code,
      boost: parseFloat(Boost.replace("USD", "")),
      ticketnumber: TicketNumber,
      urlpayment: TpagaPaymentUrl,
    });
    setprogress(66);
    setstep(2);
  };
  const RejectDeposit = async () => {
    let deposit = openDeposit;
    deposit.Status = "Rejected";
    deposit.RejectReason = "Closed by customer";
    await closeDeposit(deposit);
    setstep(1);
    setprogress(33);
  };
  return (
    <TpagaContext.Provider
      value={{
        loading,
        steps,
        setstep,
        dataUser,
        setDataUser,
        id_tpaga,
        progress,
        setprogress,
        getinfotpaga,
        usernotfound,
        setusernotfound,
        disabled,
        setdisabled,
        onChangeSignUp,
        RegisterUser,
        idinvalidated,
        emailinvalidated,
        registersuccess,
        setregistersuccess,
        CalculateFee,
        onChangeTransaction,
        dataTransaction,
        CreateDeposit,
        openDeposit,
        ContinueDeposit,
        RejectDeposit,
        setValidationCognito,
        validationCognito,
        setMessageCognito,
        messageCognito,
        setVisibleOpt,
        visibleOpt,
        setUsername,
        username,
        setDestination,
        destination,
      }}
    >
      {children}
    </TpagaContext.Provider>
  );
};

import React, { useContext } from "react";
import { Form, Button, Row, Col, Image, Alert } from "react-bootstrap";
import FloatingLabel from "react-bootstrap-floating-label";
import imgLoading from "../../public/images/loading.gif";
import { TpagaContext } from "../../context/TpagaProvider";

import ModalOptComponent from "../../components/modals/ModalOpt";

const SignUpForm = () => {
  const {
    validated,
    RegisterUser,
    onChangeSignUp,
    dataUser,
    emailinvalidated,
    idinvalidated,
    loading,
    disabled,
    setprogress,
    setstep,
    validationCognito,
    messageCognito,
    visibleOpt,
    username,
    destination,
  } = useContext(TpagaContext);

  const AlertComponent = ({ summary, variant = "success" }) => (
    <Alert variant={variant}>
      <p>{summary}</p>
    </Alert>
  );

  return (
    <>
      <Form noValidate validated={validated} onSubmit={RegisterUser}>
        <Row className="mb-3">
          <Form.Group as={Col} md="4" controlId="validationCustom01">
            <FloatingLabel
              label="Nombres"
              id="full_name"
              name="full_name"
              type="text"
              onChange={(e) => {
                onChangeSignUp(
                  e.target.id.replace("-input", ""),
                  e.target.value
                );
              }}
              initialValue={dataUser.full_name}
              required
            ></FloatingLabel>
            <Form.Control.Feedback>Luce bien!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Por favor elige un apellido
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="validationCustom02">
            <FloatingLabel
              label="Apellidos"
              id="last_name"
              type="text"
              onChange={(e) => {
                onChangeSignUp(
                  e.target.id.replace("-input", ""),
                  e.target.value
                );
              }}
              initialValue={dataUser.last_name}
              required
            ></FloatingLabel>
            <Form.Control.Feedback>Luce bien!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Por favor elige un apellido
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="validationCustomUsername">
            <FloatingLabel
              label="Correo"
              id="email"
              name="email"
              type="email"
              onChange={(e) => {
                onChangeSignUp(
                  e.target.id.replace("-input", ""),
                  e.target.value
                );
              }}
              initialValue={dataUser.email}
              required
            ></FloatingLabel>
            {emailinvalidated ? (
              <div>
                <Form.Text className="text-red">
                  No puedes usar este correo, intenta de nuevo.
                </Form.Text>
              </div>
            ) : null}
            <Form.Control.Feedback>Luce bien!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              No puedes usar este correo, intenta de nuevo.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="validationCustomUsername">
            <FloatingLabel
              label="Identificación"
              id="identification"
              name="identification"
              type="number"
              onChange={(e) => {
                onChangeSignUp(
                  e.target.id.replace("-input", ""),
                  e.target.value
                );
              }}
              initialValue={dataUser.identification}
              required
            ></FloatingLabel>
            {idinvalidated ? (
              <div>
                <Form.Text className="text-red">
                  No puedes usar esta identificación, intenta de nuevo.
                </Form.Text>
              </div>
            ) : null}
            <Form.Control.Feedback>Luce bien!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              No puedes usar esta identificación, intenta de nuevo
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="validationCustomUsername">
            <FloatingLabel
              label="Contraseña"
              id="password"
              name="password"
              type="password"
              onChange={(e) => {
                onChangeSignUp(
                  e.target.id.replace("-input", ""),
                  e.target.value
                );
              }}
              required
            ></FloatingLabel>
            <Form.Text id="passwordHelpBlock" muted>
              La contraseña debe tener al menos 6 caracteres, una letra
              mayúscula, una letra minúscula, un número y un caracter especial
            </Form.Text>

            <Form.Control.Feedback>Luce bien!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Por favor elige una contraseña
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="validationCustomUsername">
            <FloatingLabel
              label="Celular"
              id="telephone"
              name="telephone"
              onChange={(e) => {
                onChangeSignUp(
                  e.target.id.replace("-input", ""),
                  e.target.value
                );
              }}
              initialValue={dataUser.telephone}
              required
            ></FloatingLabel>
            <Form.Control.Feedback>Luce bien!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Por favor elige un telefono
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <React.Fragment>
          <div>
            <Button
              className="continue"
              variant="success"
              size="lg"
              type="submit"
              disabled={
                !dataUser.full_name ||
                !dataUser.last_name ||
                !dataUser.email ||
                !dataUser.identification ||
                !dataUser.password ||
                !dataUser.telephone ||
                disabled
              }
            >
              Continuar
            </Button>
            <br></br>
            <br></br>
            <Button
              className="continue"
              variant="primary"
              size="lg"
              onClick={() => {
                setstep(4);
                setprogress(1);
              }}
            >
              Volver
            </Button>
          </div>
          {loading && <Image className="img-loading" src={imgLoading} fluid />}

          {validationCognito && (
            <AlertComponent summary={messageCognito} variant="danger" />
          )}
        </React.Fragment>
      </Form>
      {visibleOpt && (
        <ModalOptComponent
          visible={visibleOpt}
          digitalNumber={6}
          username={username}
          destination={destination}
        />
      )}
    </>
  );
};
export default SignUpForm;

import React, { useContext } from "react";
import { Modal, Image, Row, Button } from "react-bootstrap";
import check from "../../public/images/check.png";

import { TpagaContext } from "../../context/TpagaProvider";

const ModalRegisterSuccess = () => {
  const { registersuccess, setstep, setregistersuccess, setVisibleOpt } =
    useContext(TpagaContext);
  return (
    <>
      <Modal
        show={registersuccess}
        fullscreen="xxl-down"
        onHide={() => {
          setstep(4);
          setregistersuccess(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Image className="img-bitpoint" src={check} fluid />
          <Row>
            <h3 className="welcome">Registro exitoso</h3>
          </Row>
          {/* <Row className="welcome">
            Hemos enviado un correo de verificación a tu cuenta:
            <b className="welcome">{dataUser.email} </b>{" "}
          </Row> */}
          <Button
            className="continue"
            variant="success"
            size="lg"
            type="submit"
            onClick={() => {
              setregistersuccess(false);
              setVisibleOpt(false);
              setstep(4);
              //document.getElementById("identification").value = datauser['identification-input'];
            }}
          >
            Continuar
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ModalRegisterSuccess;

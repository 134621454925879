import * as AWS from "aws-sdk";

AWS.config.credentials = new AWS.Credentials({
  accessKeyId: "AKIAQHM3ILC7SLMOHPYR",
  secretAccessKey: "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",
  // Opcional: sessionToken si estás utilizando roles temporales
});

AWS.config.update({
  region: "us-east-1",
});
const ssm = new AWS.SSM();
export const getUserPoolData = async () => {
  const UserPoolId =
    (await ssm
      .getParameter({
        Name: "USER_POOL_ID_CUSTOMER_PROD",
        WithDecryption: true,
      })
      .promise()
      .then((data) => data.Parameter?.Value)) || "";
  const ClientId =
    (await ssm
      .getParameter({ Name: "CLIENT_ID_CUSTOMER_PROD", WithDecryption: true })
      .promise()
      .then((data) => data.Parameter?.Value)) || "";

  return { UserPoolId, ClientId };
};

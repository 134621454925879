import { useContext } from 'react'
import { Row, Image } from 'react-bootstrap'
import ConfirmDeposit from '../components/Forms/ConfirmDeposit'
import DepositForm from '../components/Forms/DepositForm'
import SignUpForm from '../components/Forms/SignUpForm'
import check from '../public/images/check.png'
import { TpagaContext } from '../context/TpagaProvider'
import Home from '../components/Home'
import OpenDeposit from '../components/Forms/OpenDeposit'

const HomeView = () => {
    const { steps, url_widget_tpaga, } = useContext(TpagaContext)
    return (
        <>
            {steps === 1 && <div>

                <Row className="welcome">Bienvenidos a la plataforma para comprar y vender cripto con mayor presencia en América Latina</Row>
                <DepositForm />
            </div>}
            {steps === 2 && <div>

                <ConfirmDeposit />

            </div>}
            {steps === 3 && <div>

                <hr></hr>
                <Row>
                    <h3 className="welcome">Elige tu medio de pago para finalizar tu depósito</h3>
                </Row>
                <hr></hr>
                <a href={url_widget_tpaga} className="continue btn btn-success btn-lg" variant="success" size="lg">Elegir medio de pago</a>

            </div>}
            {steps === 0 && <div>

                <Row className="welcome"><h4 className="title-signup">Crea tu cuenta gratis</h4></Row>
                <hr></hr>
                <SignUpForm />
            </div>}
            {steps === 4 && <Home />}
            {steps === 5 && <div>

                <Image className="img-warning" src={check} fluid />
                <Row className="welcome">¡Has finalizado tu compra! Revisa tu cuenta en bitpoint</Row>
                {/* <Button className="continue" variant="success" size="lg" type="submit" onClick={()=>{setstep(1)}}>Finalizar</Button> */}
            </div>}
            {steps === 6 && <div>

                {/* <Image className="img-warning" src={check} fluid /> */}
               
                <OpenDeposit />
                {/* <Button className="continue" variant="success" size="lg" type="submit" onClick={()=>{setstep(1)}}>Finalizar</Button> */}
            </div>}
        </>
    )

}
export default HomeView